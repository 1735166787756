
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faPaintRoller, faPlug, faSnowplow, faTemperatureQuarter, faWrench } from '@fortawesome/free-solid-svg-icons'
import AboutCard from "../Common/AboutCard";
import BannerCarousel from "../Common/BannerCarousel";
import { Carousel } from 'react-responsive-carousel';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();

    const AboutCardsInfo = [
        {
            title:t('plumbing'),
            description: t('plumbingHome'),
            icon: <FontAwesomeIcon icon={faWrench}  />
        },
        {
            title:t('electrical'),
            description: t('electricalHome'),
            icon: <FontAwesomeIcon icon={faPlug} />
        },
        {
            title:t('hvac'),
            description: t('hvacHome'),
            icon: <FontAwesomeIcon icon={faTemperatureQuarter} />
        },
        {
            title:t('renovation'),
            description: t('renovationHome'),
            icon: <FontAwesomeIcon icon={faPaintRoller} />
        },
        {
            title:t('excavation'),
            description: t('excavationHome'),
            icon: <FontAwesomeIcon icon={faSnowplow} />
        },]

    return ( 
    <div className=''>
        <div className="h-screen">
        <BannerCarousel />
        </div>

        <div className="flex flex-col justify-center items-center text-white bg-neutral-800 px-6 md:px-16 py-16 gap-12">
        <span className="text-3xl">{t('whyWork')}</span>
      
        <div className='flex flex-col md:flex-row gap-5'>
            <div className='flex flex-col md:w-1/4 w-100 gap-3 '>
                <span >{t('trades')}</span>
                <span className='text-sm'>{t('tradesText')}</span>
            </div>
            <div className='flex flex-col md:w-1/4 w-100 gap-3 '>
                <span>{t('markets')}</span>
                <span className='text-sm'>{t('marketsText')}
                </span>     
            </div>
            <div className='flex flex-col md:w-1/4 w-100 gap-3 '>
                <span>{t('solutions')}</span>
                <span className='text-sm'>{t('solutionsText')}</span>
            </div>
            <div className='flex flex-col md:w-1/4 w-100 gap-3 '>
                <span>{t('coverage')}</span>
                <span className='text-sm'> {t('coverageText')} 
                </span>
        </div>
        </div>
        </div>
   
     
        <div className="flex flex-col justify-center items-center text-neutral-700  p-12 gap-4">
            <span className="text-3xl">{t('ourServices')}</span>
        </div>
        <div className=" w-100 px-6 md:px-16 pt-6 pb-8 ">
            
        <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} centerMode
            centerSlidePercentage={ width< 1000?100:33}>
            {AboutCardsInfo.map((info)=>{return(
                <AboutCard key={info.title} title={info.title} description={info.description} icon={info.icon} />
            )})}
        </Carousel>
        </div>
        
    </div>
     );
}
 
export default Home;