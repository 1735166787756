
import bgImage from '../Images/ServicesPic.jpg'
import plumbing from '../Images/Plumbing.jpg'
import electrical from '../Images/Electrical.jpg'
import hvac from '../Images/Hvac.jpg'
import renovation from '../Images/Renovation.jpg'
import excavation from '../Images/Excavation.jpg'
import door from '../Images/Door.jpg'
import Banner from "../Common/Banner";
import ServiceCard from "../Common/ServiceCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faPaintRoller, faPlug, faSnowplow, faTemperatureQuarter, faWrench } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'


const Services = () => {
    const { t } = useTranslation();
    const serviceCardsInfo = [
        {
            image:plumbing,
            title:t('plumbing'),
            description: t('plumbingServices'),
            icon: <FontAwesomeIcon icon={faWrench}  />
        },
        {
            image:electrical,
            title:t('electrical'),
            description: t('electricalServices'),
            icon: <FontAwesomeIcon icon={faPlug} />
        },
        {
            image:hvac,
            title:t('hvac'),
            description:t('hvacServices'),
            icon: <FontAwesomeIcon icon={faTemperatureQuarter} />
        },
        {
            image:renovation,
            title:t('renovation'),
            description:t('renovationServices'),
            icon: <FontAwesomeIcon icon={faPaintRoller} />
        },
        {
            image:excavation,
            title:t('excavation'),
            description: t('excavationServices'),
            icon: <FontAwesomeIcon icon={faSnowplow} />
        },
        {
            image:door,
            title:t('doorGlass'),
            description: t('doorGlassServices'),
            icon: <FontAwesomeIcon icon={faDoorOpen} />
        },
    ]

    return ( <>
    <Banner bgImage={bgImage} title={t('ourServices')}/>
    <div className="grid grid-cols-1 md:grid-cols-2 px-8 md:px-12 lg:px-28 py-12 gap-12">
        {serviceCardsInfo.map((info)=>{return(
            <ServiceCard key={info.title} image ={info.image} title={info.title} description={info.description} icon={info.icon} />
        )})}

    </div>
    </> );
}
 
export default Services;