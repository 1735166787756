
const Banner = ({bgImage,title}:any) => {
    return (
        <div className={`banner justify-center h-screen bg-no-repeat bg-cover bg-center  flex items-center `} 
            style={{ backgroundImage: `url(${bgImage})`}} >
           
        <h1 className='text-4xl md:text-6xl text-white '>{title}</h1>
    </div> );
}
 
export default Banner;