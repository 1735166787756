import { useState } from "react";
import useClickOutside from "../Hooks/useClickOutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function Dropdown({ children }:any) {
    
    const [show, setShow] = useState(false);
    const dropRef = useClickOutside(() => setShow(false))
console.log(show)
    return (
        <div className="w-fit relative" ref={dropRef} onClick={() => setShow(curr => !curr)}>
            <div><FontAwesomeIcon icon={faBars} /></div>
            {show && <ul className="w-screen h-screen absolute top-6 -right-4 mt-5 bg-black/90 flex flex-col justify-center items-center gap-12 ">{children}</ul>}
        </div>
        
    )
}

export function DropdownItem({ children }:any) {
    return (
        <li className="flex gap-3 items-center px-4 py-2 text-white hover:bg-gray-50 text-2xl  cursor-pointer">{children}</li>
    )
}