import AboutCard from "../Common/AboutCard";
import Banner from "../Common/Banner";
import bgImage from '../Images/AboutPic.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye, faEye, faMedal, faRecycle, } from '@fortawesome/free-solid-svg-icons'
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
const About = () => {
    
    const { t } = useTranslation();
        const AboutCardsInfo = [
                {
                    title:t('integrity'),
                    description:t('integrityInfo') ,
                    icon: <FontAwesomeIcon icon={faHandshake} />
                },
                {
                    title:t('sustainability'),
                    description:t('sustainabilityInfo'),
                    icon: <FontAwesomeIcon icon={faRecycle} />
                },
                {
                    title:t('excellence'),
                    description:t('excellenceInfo') ,
                    icon: <FontAwesomeIcon icon={faMedal} />
                },
                {
                    title:t('mission'),
                    description:t('missionInfo') ,
                    icon: <FontAwesomeIcon icon={faBullseye} />
                },
                {
                    title:t('vision'),
                    description:t('visionInfo') ,
                    icon: <FontAwesomeIcon icon={faEye} />
                },
            ]

    return ( <div className="about">
     <Banner bgImage={bgImage} title={t('aboutUs')}/>
     <div className="flex flex-col justify-center items-center text-neutral-700 p-8 md:p-12 gap-4">
        <span className="text-xl font-bold">{t('allServices')}</span>
        <span className="text-lg">{t('aboutInfo')}</span>
     </div>
     <div className="flex items-center justify-center">
     <div className="md:w-2/3 p-8 md:p-0 flex flex-col items-center text-center text-sm gap-3 justify-center"> 
                 <span className="text-md font-bold">{t('who')}</span>
            <span className="pb-2">{t('whoInfo')}</span>
           </div>
            </div>
     <div className="flex flex-col md:flex-row justify-around   text-neutral-700  p-8 gap-12 md:gap-4">
        <div className="md:w-1/3 flex flex-col text-sm gap-3">
          <span className="text-md font-bold">{t('whyWork')}</span>
            <span className=" font-bold">{t('value')}</span>
            <span>{t('valueInfo')}</span>
            <span className=" font-bold">{t('assisstace')}</span>
            <span>{t('assistanceInfo')}</span>
            <span className=" font-bold">{t('multiStateCoverage')}</span>
            <span>{t('multiStateCoverageInfo')}</span>
        </div>
        <div  className="md:w-1/3 flex flex-col text-sm gap-3">
            <span className="text-md font-bold">{t('planTitle')}</span>
            <span className="text-md font-bold">{t('plan')}</span>
            <span>{t('planInfo')}</span>
            <span className="text-md font-bold">{t('prepare')}</span>
            <span>{t('prepareInfo')}</span>
            <span className="text-md font-bold">{t('perform')}</span>
            <span>{t('performInfo')}</span>
            <span className="text-md font-bold">{t('perfect')}</span>
            <span>{t('perfectInfo')}</span>
        </div>
     </div>
     <div className="">
     <div className="flex flex-col justify-center items-center text-neutral-700  p-6 gap-4">
        <span className="text-3xl">{t('ourCoreValues')}</span>
     </div>
     <div className="about-container px-6  md:px-28 pt-6 pb-12 ">
        {AboutCardsInfo.map((info)=>{return(
            <AboutCard key={info.title} title={info.title} description={info.description} icon={info.icon} />
        )})}

    </div>
     </div>
    </div> );
}
 
export default About;