import { Icon } from '@rsuite/icons';
import PhoneFillIcon from '@rsuite/icons/PhoneFill';
import TimeIcon from '@rsuite/icons/Time';
import EmailIcon from '@rsuite/icons/EmailFill';
import { useTranslation } from 'react-i18next';
const TopNav = () => {
    const { t } = useTranslation();
    return ( 
    <div className="navbar hidden  bg-black md:flex gap-2 md:gap-6  md:flex-row justify-end w-100 text-sm md:text-base p-3 md:py-2 md:px-24 text-white border-b-black">
        <div className="phone flex items-center gap-2">
        <Icon as={PhoneFillIcon} />
            <span><a href="tel:4388017701">(438) 801-7701</a></span>
        </div>
         <div className="availability flex items-center gap-2">
            <Icon as={TimeIcon}/>
            <span>24 {t('availability')}</span>
        </div>
        <div className="email flex items-center gap-2">
        <Icon as={EmailIcon}/>
            <span><a href="mailto:info@moonstonemaintenance.com">info@moonstonemaintenance.com</a></span>
        </div>
        
       
    </div> );
}
 
export default TopNav;