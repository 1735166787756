
import Banner from './Banner';

import assistance from '../Images/AssistancePic.jpg'
import coverage from '../Images/CoveragePic.jpg'
import value from '../Images/ValuePic.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'react-i18next';

const BannerCarousel = () => {
    const { t } = useTranslation();
    return(
    <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
        <div className='banner'><Banner bgImage={assistance} title={t('assistance')}/></div>
        <div className='banner'><Banner bgImage={coverage} title={t('wideCoverage')}/></div>
        <div className='banner'><Banner bgImage={value} title={ t('valueEngineering')}/></div>
   
    </Carousel>
   
    )
}

export default BannerCarousel;