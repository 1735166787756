import PhoneFillIcon from '@rsuite/icons/PhoneFill';
import TimeIcon from '@rsuite/icons/Time';
import EmailIcon from '@rsuite/icons/EmailFill';
import { Icon } from '@rsuite/icons';
import { useTranslation } from 'react-i18next';
const Footer = () => {
    const { t } = useTranslation();
    return (  
    <div className="bg-black/90 flex flex-col sm:flex-row gap-6 justify-around items-center py-4 px-24 text-white">
                <div>
                    <img src={require("../Images/Logo.png")} className="w-36 " /> </div>

    <div className="flex flex-col gap-2 md:gap-6 text-sm md:text-md">
    <div className="phone flex items-center gap-2">
        <Icon as={PhoneFillIcon} />
        <span><a href="tel:4388017701">(438) 801-7701</a></span>
        </div>
         <div className="availability flex items-center gap-2">
            <Icon as={TimeIcon}/>
            <span>24 {t('availability')}</span>
        </div>
        <div className="email flex items-center gap-2">
        <Icon as={EmailIcon}/>
            <span><a href="mailto:info@moonstonemaintenance.com">info@moonstonemaintenance.com</a></span>
        </div>
       
    </div>

</div>  );
}
 
export default Footer;