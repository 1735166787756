
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import TopNav from "./Components/TopNav";
import { ScrollToTop } from "./Components/ScrollToTop";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import About from "./Pages/About";
import Footer from "./Components/Footer";
import './i18n'

function App() {
  
  return (

      <BrowserRouter>
      <ScrollToTop />
      <div className="fixed top-0 right-0 left-0  w-100 z-40 ">
      <TopNav />
      <Navbar />
      </div>
        <Routes>
          <Route
            path="/"
            element={
              <div className="z-50">
                <Home />
              </div>
            }
          />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </BrowserRouter>
  );
}

export default App;
