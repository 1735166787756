import { Link } from "react-router-dom";
import Dropdown, { DropdownItem } from "./Dropdown";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
    const { t, i18n: {changeLanguage, language} } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("lang")||"en")
    const handleChangeLanguage = () => {
      const newLanguage = currentLanguage === "en" ? "fr" : "en";
      setCurrentLanguage(newLanguage);
      changeLanguage(newLanguage);
      localStorage.setItem("lang",newLanguage)
    }
    return ( 
    <div className="navbar bg-black/90 flex gap-6 justify-between items-center w-100 py-4 px-4 md:px-24 text-white">
        <div>
          <img src={require("../Images/Logo.png")} className="w-24 md:w-36 " />
           </div>
        <div className="hidden sm:flex gap-6 text-lg">
       
    
            <Link to="/">{t('home')}</Link>
            <Link to="/services">{t('services')}</Link>
            <Link to="/about">{t('about')}</Link>
           
        </div>
         <div className="hidden sm:flex gap-2 items-center"><FontAwesomeIcon icon={faGlobe} /> <button 
        type="button" 
        onClick={handleChangeLanguage}
     >
      {currentLanguage === "en" ? "en" : "fr"}
     </button>
     </div>
        <div className="sm:hidden flex ">
        <Dropdown >
        
            <DropdownItem>
            <Link to="/">{t('home')}</Link>
            </DropdownItem>
            <DropdownItem>
            <Link to="/services">{t('services')}</Link>
            </DropdownItem>
            <DropdownItem>
            <Link to="/about">{t('about')}</Link>
            </DropdownItem>
              <div><FontAwesomeIcon icon={faGlobe} /> <button 
        type="button" 
        onClick={handleChangeLanguage}
     >
      {currentLanguage === "en" ? "en" : "fr"}
     </button>
     </div>
          </Dropdown>
        
        </div>
    
    </div> );
}
 
export default Navbar;