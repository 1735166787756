

const ServiceCard = ({image,title,description,icon}:any) => {


    return ( 
    <div className="bg-neutral-200 w-100 h-[35rem] text-neutral-700">
         <div className={`justify-center h-[40%] bg-no-repeat bg-cover bg-center bg-neutral-500  flex items-center `} 
            style={{ backgroundImage: `url(${image})`}} >
          </div>
          <div className='px-4 md:px-12 relative'>
            <div className='flex justify-center'>
          <div className=' w-24 h-24 text-4xl rounded-full flex justify-center items-center absolute -top-12  bg-neutral-300'>
            {icon}
          </div>
          </div>
          <div className='flex flex-col items-center py-16  justify-center gap-4'>
            <span className='text-xl'>{title}</span>
            <span className='text-sm'>{description}</span>
          </div>
          </div>
    </div> );
}
 
export default ServiceCard;