

const AboutCard = ({title,description,icon}:any) => {


    return ( 
    <div className="item w-100 py-5  text-gray-700">
        
          <div className='px-12 '>
            <div className='flex justify-center'>
          <div className=' w-24 h-24 text-4xl rounded-full flex justify-center items-center  bg-gray-300'>
            {icon}
          </div>
          </div>
          <div className='flex flex-col items-center py-2 text-center justify-center gap-4'>
            <span className='text-xl'>{title}</span>
            <span className='text-sm'>{description}</span>
          </div>
          </div>
    </div> );
}
 
export default AboutCard;